import { subscribeTo, UnsubscribeFrom } from "../helpers/db-reader";

export default class RepoObserver {
	constructor(id, repo) {
		this.id = id;
		this.repo = repo;
		this.firstUpdate = true;
		
		this.itemAdded = this.itemAdded.bind(this);
		this.destroy = this.destroy.bind(this);
		
		this.listener = subscribeTo(id, this.itemAdded);
	}
	
	itemAdded(snapshot) {
		if (this.firstUpdate) {
			this.firstUpdate = false;
			return;
		} else {
			this.repo.add({value: snapshot.val(), key: snapshot.key});
		}
	}
	
	destroy() {
		UnsubscribeFrom(this.id, this.listener);
	}
}