import logo from './logo.svg';
import './App.css';

import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { auth } from './services/firebase';
import { DataProvider } from './context/DataContext';

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === false
        ? <Component {...props} />
        : <Redirect to='/app' />}
    />
  )
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  }
  
  componentDidMount() {
	auth().onAuthStateChanged((user) => {
		if (user) {
			this.setState({
				authenticated: true,
				loading: false,
			});
		} else {
			this.setState({
				authenticated: false,
				loading: false,
			});
		}
	})
  }
  
  render() {
  return this.state.loading === true ?
    <Container component="main" maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
	  <CssBaseline />
	  <h2>Loading...</h2>
	</Container> :
	(<DataProvider>
      <Router>
        <Switch>
	  	<Route exact path="/" render={() => <Redirect to="/app" />} />
          <PrivateRoute  path="/app" authenticated={this.state.authenticated} component={Dashboard}></PrivateRoute>
          <PublicRoute path="/login" authenticated={this.state.authenticated} component={Login}></PublicRoute>
	  	<Route render={() => <h1>Not found!</h1>} />
        </Switch>
      </Router>
    </DataProvider>);
  }
}

export default App;
