import { db } from "../services/firebase";

export async function getInfo() {
	let factions = [];
	try {
		let snapshot = await db.ref('info').get();
		if (snapshot && snapshot.exists()) {
			snapshot.forEach(snap => {
				factions.push({id: snap.key, tag: snap.val().tag});
				});
		}
	} catch(error) {
		console.error(error);
		throw error;
	}
	return factions;
}

export async function getDataById(id) {
	let records = new Map();
	let snapshot = await db.ref(`factions/${id}`).get();
	if (snapshot && snapshot.exists()) {
		snapshot.forEach(snap => {
			records.set(snap.key, snap.val());
		});
	}
	return records;
}

export function subscribeTo(id, callback) {
	console.log('subscribed to: ' + id);
	return db.ref(`factions/${id}`).limitToLast(1).on('child_added', (snapshot, prevKey) => {
		//console.log('child_added fired:');
		//console.log('prevKey: ' + prevKey);
		//console.log(snapshot);
		if (callback)
			callback(snapshot);
	});
}

export function UnsubscribeFrom(id, listener) {
	console.log('unsubscribed from: ' + id);
	db.ref(`factions/${id}`).off('child_added', listener);
}