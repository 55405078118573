import React, { useState, useEffect } from "react";
import CheckIcon from '@material-ui/icons/Check';
import InfoCard from '../components/InfoCard';
import { subscribeTo, UnsubscribeFrom } from "../helpers/db-reader";

export default function CurrentRecord(props) {
	const [count, setCount] = useState(0);
	
	useEffect(() => {
		function callback(snap) {
			setCount(snap.val());
		}
		let listener = subscribeTo(props.fid, callback);
		return () => {
			UnsubscribeFrom(props.fid, listener);
		};
	}, []);
	
	return (
	  <InfoCard title={count} subTitle="Online players now" Icon={CheckIcon} iconColor="green"/>
	);
}