import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { BarChart, Bar, XAxis, YAxis, Label, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const timeFormater = (tick) => weekDays[tick];

export default function WeeklyChart(props) {
  const theme = useTheme();

  return (
      <ResponsiveContainer>
        <BarChart
          data={props.data}
          margin={{
            top: 16,
            right: 8,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis type="category" domain={['dataMin', 'dataMax']} tickFormatter={timeFormater} dataKey="time" stroke={theme.palette.text.secondary} interval="preserveStartEnd" tickLine={true} />
		  <YAxis type="number" width={40} hide={false} />
		  <CartesianGrid vertical={false} style={{shapeRendering: "crispedges"}} strokeDasharray="3 3" />
		  <Tooltip labelFormatter={timeFormater} />
          <Bar type="monotone" connectNulls={false} dataKey="amount" fill={theme.palette.primary.main} dot={true} barSize={30}/>
        </BarChart>
      </ResponsiveContainer>
  );
}