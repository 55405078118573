import { getInfo, getDataById } from "./db-reader";
import RecordsRepo from "./records"
import RepoAnalyzer from "./analyzer";
import RepoObserver from "./observer";

const getFromLast = (arr, index = 0) => arr[arr.length - index - 1];
const dateToDayId = dt => new Date(Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate())).getTime(); //Date() constructor take args of LOCAL time

const utcDiff = () => {
	let dt = new Date();
	return dt.getHours() - dt.getUTCHours();
}

export default async function createRepos() {
	let repos = [];
	try {
		let info = await getInfo();
		if (info) {
			for (const fac of info) {
				let data = await getDataById(fac.id);
				let repo = new RecordsRepo();
				if (data) {
					repo.fill(data);
					repo.sort();
				}
				repos.push({id: fac.id, tag: fac.tag, analyzer: new RepoAnalyzer(repo), observer: new RepoObserver(fac.id, repo)});
			}
		}
		//console.log(repos);
	} catch(error) {
		throw error;
	}
	return repos;
}

function getLast24HRecords(repo) {
	if (repo.records.length <= 0) {
		return undefined;
	}
	let l24h = []
	let lastDay = getFromLast(repo.records);
	if (lastDay.items.length < 24) {
		let beforeLastDay = getFromLast(repo.records, 1);
		for (let i = 0; i < 24 - lastDay.items.length; i++) {
			l24h.push(beforeLastDay.items[lastDay.items.length + i]);
		}
	}
	Array.prototype.push.apply(l24h, lastDay.items);
	//console.log(l24h);
	return l24h;
}

function getLast24HAverage(repo) {
	let rcs = getLast24HRecords(repo);
	
	if (rcs) {
		return rcs.reduce((s, r) => s + r.average(), 0) / rcs.length; //s = sum ... r = record ;)
	}
}

function getDaysRecordsForInterval(repo, start, stop) {
	if (repo.records.length <= 0) {
		return undefined;
	}
	let tsStart = dateToDayId(start);
	let tsStop = dateToDayId(stop);
	let result = repo.records.filter(r => r.id >= tsStart && r.id <= tsStop);
	return result;
}

function mapRecordsToWeekDays(data) {
	let result = new Map();
	data.forEach(r => {
		let realTS = r.id + utcDiff() * 60 * 60 * 1000;
		let dt = new Date(realTS);
		let day = dt.getUTCDay();
		if (!result.has(day)) {
			result.set(day, []);
		}
		result.get(day).push(r);
	});
	let avgs = new Array(7).fill(0);
	result.forEach((value, key) => {
		avgs[key] = value.reduce((s, r) => s + r.average(), 0) / value.length; //s = sum ... r = record
	});
	return avgs;
}

function mapRecordsToHours(data) {
	let result = new Map();
	data.forEach(r => {
		r.items.forEach(h => { //h --> recordHour
			if (!result.has(h.id)) {
				result.set(h.id, []);
			}
			result.get(h.id).push(h);
		});
	});
	let avgs = new Array(24).fill(0);
	result.forEach((value, key) => {
		avgs[key] = value.reduce((s, r) => s + r.average(), 0) / value.length; //s = sum ... r = record
	});
	return avgs;
}

export function extract24hData(repo) {
	let l24Data = getLast24HRecords(repo);
	let l24Avg = getLast24HAverage(repo);
	
	return { avg: l24Avg, records: l24Data };
}

export function extractWeekData(repo, start, stop) {
	if (!start) { start = new Date(0); }
	if (!stop) { stop = new Date(); }
	let data = getDaysRecordsForInterval(repo, start, stop);
	return mapRecordsToWeekDays(data);
}

export function extractHourlyData(repo, start, stop) {
	if (!start) { start = new Date(0); }
	if (!stop) { stop = new Date(); }
	let data = getDaysRecordsForInterval(repo, start, stop);
	return mapRecordsToHours(data);
}