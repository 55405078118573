import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
  },
}));

const iconsColors = new Map([
	["green", teal[500]],
	["blue", blue[500]],
	["yellow", yellow[700]],
	["red", red[500]]
]);

export default function InfoCard({title, subTitle, children, Icon, iconColor, ...rest}) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} {...rest} >
	  <Grid container>
	    <Grid item xs>
          <Typography variant="h6" component="h2" gutterBottom>{title}</Typography>
	      <Typography variant="body2" color="textSecondary" component="p">{subTitle}</Typography>
	      {children}
		</Grid>
		<Grid item>
		  {Icon ? <Icon fontSize="large" style={{ color: iconsColors.get(iconColor) }} /> : null}
		</Grid>
	  </Grid>
    </Paper>
  );
}
