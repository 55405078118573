import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  /*DateTimePicker,*/
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

export default function DateRangeDialog(props) {
  const { onClose, selectedRange, open } = props;
  
  const [range, setRange] = React.useState({ start: selectedRange.start, end: selectedRange.end });

  const handleClose = () => {
    if (onClose) {
		onClose(null)
	}
  };
  
  const handleClick = () => {
	if (onClose) {
		onClose(range)
	}
  };
  
  const handleStartDate = date => {
	//console.log("handleStartDate", date);
	setRange(current => ({ start: date, end: current.end }));
  };
  
  const handleStopDate = date => {
	setRange(current => ({ start: current.start, end: date }));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
	  >
        <DialogTitle id="alert-dialog-title">{"Date & Time range"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pick DateTime for starting and ending point.
          </DialogContentText>
		  <MuiPickersUtilsProvider utils={DateFnsUtils}>
		    <div style={{ padding: '6px 0' }}>
		      <Grid container spacing={3}>
			    <Grid item xs={12} sm>
		          <Typography variant="subtitle2" gutterBottom>From:</Typography>
			      <KeyboardDateTimePicker variant="inline" ampm={false} onChange={handleStartDate} value={range.start} disableFuture disableToolbar fullWidth />
			    </Grid>
			    <Grid item xs sm>
			      <Typography variant="subtitle2" gutterBottom>To:</Typography>
			      <KeyboardDateTimePicker variant="inline" ampm={false} onChange={handleStopDate} value={range.end} disableFuture disableToolbar fullWidth />
			    </Grid>
			  </Grid>
			</div>
		  </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
		  <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
