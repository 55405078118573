import { extractWeekData, extractHourlyData, extract24hData } from "./repo-manager";

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export default class RepoAnalyzer {
	constructor(repo) {
		this.repo = repo;
		this.startDate = new Date(0);
		this.stopDate = new Date();
		this.needWRefresh = true;
		this.needHRefresh = true;
		this.internalWeeklyData = null;
		this.internalHourlyData = null;
		
		repo.setCallback(() => {
			this.needHRefresh = true;
			this.needWRefresh = true;
		});
	}
	
	weeklyData() {
		if (this.needWRefresh) {
			this.internalWeeklyData = extractWeekData(this.repo, this.startDate, this.stopDate);
			this.needWRefresh = false;
		}
		return this.internalWeeklyData;
	}
	
	hourlyData() {
		if (this.needHRefresh) {
			this.internalHourlyData = extractHourlyData(this.repo, this.startDate, this.stopDate);
			this.needHRefresh = false;
		}
		return this.internalHourlyData;
	}
	
	setDateInterval(start, stop) {
		if (!start || !stop)
			return;
		this.startDate = start;
		this.stopDate = stop;
		this.needWRefresh = true;
		this.needHRefresh = true;
	}
	
	get last24hData() {
		return extract24hData(this.repo);
	}
	
	get topRecord() {
		let max = 0;
		let ts = 0; // will be the timestamp for the peek if needed
		this.repo.records.forEach(r => { // r --> recordDay
			r.items.forEach(h => { // h --> recordHour
				h.items.forEach(s => { // s --> single value
					let _ts = r.id + h.id * 60 * 60 * 1000;
					if (s > max && _ts >= this.startDate.getTime() && _ts <= this.stopDate.getTime()) {
						max = s;
						ts = _ts;
					}
				})
			})
		});
		return { record: max, date: new Date(ts) };
	}
	
	get mostActiveDay() {
		let i = 0;
		for(let index = 0; index < this.weeklyData().length; index++) {
			if (this.weeklyData()[index] > this.weeklyData()[i]) {
				i = index;
			}
		}
		return weekDays[i];
	}
	
	get mostActiveZone() {
		let imax = 0;
		for (let i = 0; i < this.hourlyData().length; i++) {
			if (this.hourlyData()[i] > this.hourlyData()[imax]) {
				imax = i;
			}
		}
		return [imax, imax + 1];
	}
	
	get mostInactiveZone() {
		let imin = 0;
		for (let i = 0; i < this.hourlyData().length; i++) {
			if (this.hourlyData()[i] < this.hourlyData()[imin]) {
				imin = i;
			}
		}
		return [imin, imin + 1];
	}
	
	get trackingStart() {
		return this.repo.records[0].items[0].time; // it won't be exact in terms of minutes and seconds but we just need year/month/day/(hour?)
	}
}