import React from "react";

const LayoutStateContext = React.createContext();
const LayoutDispatchContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'ViewDetails':
      return { canGoBack: true, detailsData: action.payload };
	case 'GoBack':
      return { canGoBack: false, detailsData: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(reducer, {
    canGoBack: false,
	detailsData : null
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

export { LayoutProvider, LayoutStateContext, LayoutDispatchContext };