import React from "react";

const DataStateContext = React.createContext();
const DataDispatchContext = React.createContext();
const DataContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'Set':
	  console.log("DataContext: Set");
      return { data: action.payload };
	case 'Reset':
      return { data: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DataProvider({ children }) {
  var [state, dispatch] = React.useReducer(reducer, {
    data : null
  });
  return (
    <DataStateContext.Provider value={state}>
      <DataDispatchContext.Provider value={dispatch}>
	    <DataContext.Provider value={{state, dispatch}}>
          {children}
		</DataContext.Provider>
      </DataDispatchContext.Provider>
    </DataStateContext.Provider>
  );
}

export { DataProvider, DataStateContext, DataDispatchContext, DataContext };