import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyDPeH2qTIdGFaicrlTA4Kb3NKz31_QkvHk",
  authDomain: "firetest-5f75f.firebaseapp.com",
  databaseURL: "https://firetest-5f75f.firebaseio.com",
  storageBucket: "firetest-5f75f.appspot.com"
};
firebase.initializeApp(config);
export const auth = firebase.auth;
export const db = firebase.database();