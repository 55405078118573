import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const timeFormater = (tick) => new Date(tick).getUTCHours();

export default function Chart(props) {
  const theme = useTheme();

  return (
      <ResponsiveContainer>
        <AreaChart
          data={props.data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 16,
          }}
        >
          <XAxis type="number" domain={['dataMin', 'dataMax']} tickFormatter={timeFormater} dataKey="time" stroke={theme.palette.text.secondary} interval="preserveStartEnd" tickCount={5} tickLine={false} />
		  <YAxis type="number" domain={[0, 100]} tickCount={3} hide />
		  <CartesianGrid vertical={false} style={{shapeRendering: "crispedges"}} />
          <Area type="monotone" connectNulls={false} dataKey="amount" stroke={theme.palette.primary.main} fill={theme.palette.primary.main} fillOpacity={0.1} dot={false} />
        </AreaChart>
      </ResponsiveContainer>
  );
}