import createRepos from "../helpers/repo-manager";
import { signout } from "../helpers/auth";
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import SimpleCard from '../components/SimpleCard';
import { DataStateContext, DataDispatchContext, DataContext } from '../context/DataContext';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
	  marginBottom: theme.spacing(3),
  }
});

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
		loading: true,
		factions: [],
		error: null,
		alert: false
    };
  }
  
  componentDidMount() {
	  window.scrollTo(0, 0);
	  if (this.context.state.data) {
		  this.setState({factions: this.context.state.data, loading: false});
		  return;
	  }
	  createRepos().then(data => {
		  console.log("Data fetched!");
		  let sortedData = data.sort((a, b) => a.tag.toLowerCase() > b.tag.toLowerCase() ? 1 : -1);
		  this.context.dispatch({type: 'Set', payload: sortedData});
		  this.setState({factions: sortedData, loading: false})
		}).catch(error => this.setState({loading: false, error: error.message, alert: true}));
  }
  
  render() {
	const {classes} = this.props;
	
	return this.state.loading ?
	  <Box display="flex"><CircularProgress style={{ margin: '0 auto' }} /></Box> :
	  this.state.error ?
	  (
	  <Dialog open={this.state.alert} onClose={() => this.setState({alert: false})}>
	    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		    {this.state.error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => signout()} color="secondary">
            Logout
          </Button>
          <Button onClick={() => this.setState({alert: false})} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
	  </Dialog>
	  ) :
	  (
      <>
        <CssBaseline />
        <Typography variant="h5" className={classes.title}>Overview</Typography>
		<Grid container spacing={3}>
		  {this.state.factions.map(fc =>
			<Grid item xs={12} sm={6} md={3} key={fc.tag}><SimpleCard data={fc} /></Grid>
		  )}
		</Grid>
	  </>
    );
  }
}

Overview.contextType = DataContext;

export default withStyles(styles)(Overview);