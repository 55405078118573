//const lookup = new Map([["hour", recordHour], ["day", recordDay], ["week", recordWeek], ["month", recordMonth]]);

const dtHour  = dt => dt.getUTCHours();
//const dtDay   = dt => dt.getUTCDate();
//const dtWeek  = dt => dt.getUTCWeek();
//const dtMonth = dt => dt.getUTCMonth();
//const dtYear  = dt => dt.getUTCFullYear();

const dateToDayId = dt => new Date(Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate())).getTime(); //Date() constructor take args of LOCAL time

//const getFromLast = (arr, index = 0) => arr[arr.length - index - 1];

class recordHour {
	constructor(id) {
		this.items = [];
		this.id = id;
		this.time = 0;
		this.calculatedAverage = null;
		
		this.average = this.average.bind(this);
		this.add = this.add.bind(this);
	}
	
	average() {
		if (!this.calculatedAverage) {
			let avg = 0;
			for(let i = 0; i < this.items.length; i++) {
				avg += this.items[i];
			}
			this.calculatedAverage = avg / this.items.length;
		}
		return this.calculatedAverage;
	}
	
	add(record) {
		if (record.dt.getUTCHours() === this.id) {
			this.time = record.dt;
			this.items.push(record.value);
		}
	}
	
	sort() {}
}

class recordDay {
	constructor(id) {
		this.items = [];
		this.id = id;
		this.calculatedAverage = null;
		
		this.average = this.average.bind(this);
		this.add = this.add.bind(this);
		this.sort = this.sort.bind(this);
	}
	
	average() {
		if (!this.calculatedAverage) {
			let avg = 0;
			for(let i = 0; i < this.items.length; i++) {
				avg += this.items[i].average();
			}
			this.calculatedAverage = avg / this.items.length;
		}
		return this.calculatedAverage;
	}
	
	add(record) {
		if (dateToDayId(record.dt) === this.id) {
			let chId = dtHour(record.dt);
			let recChild = this.items.find(i => i.id === chId);
			if (!recChild) {
				recChild = new recordHour(chId);
				this.items.push(recChild);
			}
			recChild.add(record);
			this.calculatedAverage = null;
		}
	}
	
	sort() {
		this.items.sort((r1, r2) => r1.id - r2.id);
		this.items.forEach(r => {
			r.sort();
		});
	}
}

export default class RecordsRepo {
	constructor(){
		this.records = [];
		this.calculatedAverage = null;
		this.itemAddedCallback = null;
		
		this.fill = this.fill.bind(this);
		this.add = this.add.bind(this);
		this.average = this.average.bind(this);
		this.sort = this.sort.bind(this);
		this.setCallback = this.setCallback.bind(this);
	}
	
	fill(data) {
		data.forEach((value, key) => {
			this.add({ value, key });
		});
	}
	
	add({ value, key }) {
		let rDate = new Date(parseFloat(key));
		let newRec = { dt: rDate, value: value };
		let recDay = this.records.find(r => r.id === dateToDayId(rDate));
		if (!recDay) {
			recDay = new recordDay(dateToDayId(rDate));
			this.records.push(recDay);
		}
		recDay.add(newRec);
		this.calculatedAverage = null;
		if (this.itemAddedCallback) {
			this.itemAddedCallback();
		}
	}
	
	average() {
		if (!this.calculatedAverage) {
			let avg = 0;
			for(let i = 0; i < this.records.length; i++) {
				avg += this.records[i].average();
			}
			this.calculatedAverage = avg / this.records.length;
		}
		return this.calculatedAverage;
	}
	
	sort() {
		this.records.sort((r1, r2) => r1.id - r2.id);
		this.records.forEach(r => {
			r.sort();
		});
	}
	
	setCallback(callback) {
		this.itemAddedCallback = callback;
	}
}