import React from 'react';
import {Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { LayoutDispatchContext } from '../context/LayoutContext';

import SmallChart from '../components/SmallChart'

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const normalizeHour = dt => new Date(Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours())).getTime();
const convertDataToChartData = data => data.map(r => ({time: normalizeHour(r.time), amount: Math.round(r.average())}));

export default function SimpleCard(props) {
  const classes = useStyles();
  const viewDetailsClick = (dispatch) => {
	  if (dispatch) {
		  dispatch({ type: 'ViewDetails', payload: props.data });
	  }
  }
  const data24h = props.data.analyzer.last24hData;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom>
		  {props.data.tag}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          About <b>{Math.round(data24h.avg)}</b> in average for last 24 hours
        </Typography>
		<div style={{height: '150px'}}>
			<SmallChart data={convertDataToChartData(data24h.records)} />
		</div>
      </CardContent>
      <CardActions>
	    <LayoutDispatchContext.Consumer>
		  {(dispatch) => (
            <Button to={`/app/f/${props.data.id}`} size="small" color="primary" component={Link} onClick={() => viewDetailsClick(dispatch)}>More Details</Button>
		  )}
		</LayoutDispatchContext.Consumer>
      </CardActions>
    </Card>
  );
}