import React, { useState, useEffect } from "react";
import InfoCard from '../components/InfoCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { subscribeTo, UnsubscribeFrom } from "../helpers/db-reader";

export default function LatestRecords(props) {
	const [recList, setRecList] = useState([]);
	
	useEffect(() => {
		function callback(snap) {
			let ts = parseFloat(snap.key);
			setRecList(current => {
				let newList = [{ value: snap.val(), time: ts }, ...current];
				if (newList.length > 3) {
					newList = newList.slice(0, 3);
				}
				return newList;
			});
		}
		let listener = subscribeTo(props.fid, callback);
		return () => {
			UnsubscribeFrom(props.fid, listener);
		};
	}, []);
	
	return (
	  <InfoCard title="Latest records" subTitle="Synchronized" {...props} >
	    <List dense={true}>
		  {recList.map(r =>
		    <ListItem disableGutters key={r.time}>
		      <ListItemIcon>
				<AccessTimeIcon />
			  </ListItemIcon>
			  <ListItemText primary={r.value} secondary={new Date(r.time).toISOString()} />
			</ListItem>
		  )}
        </List>
	  </InfoCard>
	);
}