import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

//const timeFormater = (tick) => new Date(tick).getUTCHours();
const timeFormater = (tick) => z(new Date(tick).getUTCHours());
const z = (n) => ('0' + n).slice(-2);
const labelFormatter = ts => {
	let d = new Date(ts);
	return `${z(d.getUTCHours())}:00 — ${z(d.getUTCHours())}:59`
}

export default function Full24hChart(props) {
  const theme = useTheme();

  return (
      <ResponsiveContainer>
        <AreaChart
          data={props.data}
          margin={{
            top: 16,
            right: 8,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis type="category" domain={['dataMin', 'dataMax']} tickFormatter={timeFormater} interval="preserveStart" dataKey="time" stroke={theme.palette.text.secondary} tickCount={12} tickLine={true} />
		  <YAxis type="number" width={40} hide={false} />
		  <CartesianGrid vertical={false} style={{shapeRendering: "crispedges"}} strokeDasharray="3 3" />
		  <Tooltip labelFormatter={labelFormatter} />
          <Area type="monotone" connectNulls={false} dataKey="amount" stroke={theme.palette.primary.main} fill={theme.palette.primary.main} fillOpacity={0.1} dot={true} />
        </AreaChart>
      </ResponsiveContainer>
  );
}