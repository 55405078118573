import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const z = (n) => ('0' + n).slice(-2);
const timeFormater = (tick) => z(tick);
const labelFormatter = ts => {
	return `${z(ts)}:00 — ${z(ts)}:59`
}

export default function HourlyChart(props) {
  const theme = useTheme();

  return (
      <ResponsiveContainer>
        <LineChart
          data={props.data}
          margin={{
            top: 16,
            right: 8,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis type="category" domain={['dataMin', 'dataMax']} tickFormatter={timeFormater} dataKey="time" stroke={theme.palette.text.secondary} interval="preserveStartEnd" tickLine={true} />
		  <YAxis type="number" width={40} hide={false} />
		  <CartesianGrid vertical={false} style={{shapeRendering: "crispedges"}} strokeDasharray="3 3" />
		  <Tooltip labelFormatter={labelFormatter} />
          <Line type="monotone" connectNulls={false} dataKey="amount" stroke={theme.palette.primary.main} dot={true} />
        </LineChart>
      </ResponsiveContainer>
  );
}