import React from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DateRangeDialog from './DateRangeDialog';

const options = [
	'All Time',
	'Last 7 days',
	'Last 30 days',
	'Custom range...',
];

const dateOptions = { timeZone: 'UTC', timeZoneName: 'short' };
const formatDate = date => {
	return date.toLocaleDateString(undefined, dateOptions);
};
const dateFromNow = (days = 0) => {
	let ts = new Date().getTime();
	return new Date(ts - days * 24 * 60 * 60 * 1000);
};

export default function TimeFilterButton({ onFilterChanged, minDate }) {
	const minDateLimit = new Date(Date.UTC(minDate.getUTCFullYear(), minDate.getUTCMonth(), minDate.getUTCDate()));
	
	const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [selectedRange, setSelectedRange] = React.useState({ start: minDateLimit, end: new Date() });
	
	const isInitialMount = React.useRef(true);
	
	React.useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
			return;
		}
		if (onFilterChanged) {
			onFilterChanged(selectedRange);
		}
	}, [selectedRange])
	
	const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleMenuItemClick = (event, index) => {
      setAnchorEl(null);
	  if (selectedIndex !== index && index !== 3) {
        setSelectedIndex(index);
		switch (index) {
		  case 0: // All Time
		    setSelectedRange({ start: minDateLimit, end: new Date() });
		    break;
		  case 1:  // Last 7 days
		    setSelectedRange({ start: dateFromNow(7), end: new Date() });
		    break;
		  case 2: // Last 30 days
		    setSelectedRange({ start: dateFromNow(30), end: new Date() });
		    break;
		}
	  } else if (index === 3) {
		setDialogOpen(true);
	  }
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
	
	const handleDialogClose = (range) => {
	  setDialogOpen(false);
	  if (range) {
		  setSelectedIndex(3);
		  setSelectedRange(range);
	  }
	};
	
	return (
	  <>
	    <Button onClick={handleClick} startIcon={<DateRangeIcon />} endIcon={<ExpandMoreIcon />}>
		  {selectedIndex !== 3 ?
		    options[selectedIndex] :
			<>
			  {formatDate(selectedRange.start) + " "}
			  <ArrowRightAltIcon fontSize="small" style={{ margin: '0 8' }} />
			  {" " + formatDate(selectedRange.end)}
			</>}
        </Button>
	    <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
		{options.map((op, index) =>
		  <MenuItem
		    key={op}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
		  >
		  {op}
		  </MenuItem>
		)}
        </Menu>
		<DateRangeDialog open={dialogOpen} selectedRange={selectedRange} onClose={handleDialogClose} />
	  </>
	);
}