import { getInfo } from "../helpers/db-reader";
import createRepos from "../helpers/repo-manager";
import React, { Component } from "react";
import {
  Link,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '../components/Alert';
import Overview from './Overview';
import Details from './Details';
import { LayoutProvider, LayoutStateContext, LayoutDispatchContext } from '../context/LayoutContext';
import { DataProvider, DataStateContext, DataDispatchContext, DataContext } from '../context/DataContext';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
	  marginBottom: theme.spacing(3),
  }
});

const isAlertDismissed = () => {
	return localStorage.getItem("alertDismissed") === 'true';
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
		factions: null,
      error: null
    };
  }
  
  componentDidMount() {
	  
  }
  
  componentWillUnmount() {
	this.context?.state?.data?.forEach(fc => {
		fc.observer.destroy();
	});
  }
  
  render() {
	const {classes} = this.props;
	const goBackClick = (dispatch) => {
	  if (dispatch) {
		  dispatch({ type: 'GoBack', payload: null });
	  }
    }
    return (
	  <LayoutProvider>
	  {/*<DataProvider>*/}
		  <>
		    <CssBaseline />
		    <AppBar>
		      <Toolbar>
		  	  <LayoutStateContext.Consumer>
		  	    {(state) => (
		  	      <LayoutDispatchContext.Consumer>
		  	        {(dispatch) => (
		              <IconButton edge="start" className={classes.menuButton} color="inherit" style={{ visibility: state && state.canGoBack ? "visible" : "hidden" }} onClick={() => goBackClick(dispatch)} component={Link} to="/app">
		                <ArrowBackIcon />
		              </IconButton>
		  	        )}
		  	      </LayoutDispatchContext.Consumer>
		  	    )}
		  	  </LayoutStateContext.Consumer>
		        <Typography variant="h6">Activity Tracker</Typography>
		      </Toolbar>
		    </AppBar>
		    <Toolbar id="back-to-top-anchor" />
		    <Container className={classes.container}>
		      {isAlertDismissed() ? null : <Alert onClose={() => {localStorage.setItem("alertDismissed", true);}} style={{marginBottom: 24}}>Data is visualized according to UTC timezone.</Alert>}
		      <Switch>
		      	<Route exact path="/app" component={Overview} />
		      	<Route path="/app/f" component={Details} />
		      </Switch>
		    </Container>
		  </>
		  {/*</DataProvider>*/}
	  </LayoutProvider>
    );
  }
}

Dashboard.contextType = DataContext;

export default withRouter(withStyles(styles)(Dashboard));