/*
- Online now => card
- most active day => card
- most active timezone => card
- most inactive timezone => card
- Top record?
- last records => card => list
- last 24h activity => line/area chart

++ All time / last month / last 7 days:
  - Each day activity => bar chart
  - Each hour stats => line chart

++ Allow filtering data by intervals

- Indicate when did tracking start
*/
import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import CircularProgress from '@material-ui/core/CircularProgress';
import StarIcon from '@material-ui/icons/Star';
import SecurityIcon from '@material-ui/icons/Security';
import WarningIcon from '@material-ui/icons/Warning';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { withStyles } from '@material-ui/core/styles';
import TimeFilterButton from '../components/TimeFilterButton';
import InfoCard from '../components/InfoCard';
import LatestRecords from '../components/LatestRecordsWidget';
import CurrentRecord from '../components/CurrentRecordWidget';
import Full24hChart from '../components/Full24hChart'
import WeeklyChart from '../components/WeeklyBarChart'
import HourlyChart from '../components/HourlyLineChart'
import { LayoutStateContext } from '../context/LayoutContext';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  header: {
	  marginBottom: theme.spacing(3),
  },
  title: {
	flexGrow: 1,
  }
});

const normalizeHour = dt => new Date(Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours())).getTime();
const convertDataTo24hChartData = data => data.map(r => ({time: normalizeHour(r.time), amount: Math.round(r.average())}));
const convertDataToAbstractChartData = data => data.map((r, i) => ({ time: i, amount: Math.round(r) }));

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
		loading: true,
		data: null,
		error: null
    };
	
	this.updateRange = this.updateRange.bind(this);
  }
  
  componentDidMount() {
	  window.scrollTo(0, 0);
	  //console.log(this.context.detailsData);
	  this.setState({ loading: false, data: this.context.detailsData });
  }
  
  updateRange(range) {
	  console.log("selected range:", range);
	  this.state.data.analyzer.setDateInterval(range.start, range.end);
	  this.setState(current => current);
  }
  
  render() {
	const {classes} = this.props;
	let topRecord = this.state.data?.analyzer?.topRecord;
	
	return this.state.loading ? <p>Loading...</p> : !this.state.data ? <p>Nothing to show :(</p> : (
      <>
        <CssBaseline />
		<Grid container className={classes.header} >
          <Grid item xs={12} sm>
		    <Typography variant="h5" className={classes.title}>Details - {this.state.data.tag}</Typography>
		  </Grid>
		  <Grid item>
		    <TimeFilterButton onFilterChanged={this.updateRange} minDate={this.state.data.analyzer.trackingStart} />
		  </Grid>
		</Grid>
		<Grid container spacing={3}>
		  <Grid item xs={12} sm={6} md>
		    <CurrentRecord fid={this.state.data.id} />
		  </Grid>
		  <Grid item xs={12} sm={6} md>
		    <InfoCard title={this.state.data.analyzer.mostActiveDay} subTitle="The most active day" Icon={StarIcon} iconColor="yellow"/>
		  </Grid>
		  <Grid item xs={12} sm={6} md>
		    <InfoCard title={`${this.state.data.analyzer.mostActiveZone[0]} h — ${this.state.data.analyzer.mostActiveZone[1]} h`} subTitle="The most active timezone" Icon={SecurityIcon} iconColor="blue"/>
		  </Grid>
		  <Grid item xs={12} sm={6} md>
		    <InfoCard title={`${this.state.data.analyzer.mostInactiveZone[0]} h — ${this.state.data.analyzer.mostInactiveZone[1]} h`} subTitle="The most inactive timezone" Icon={WarningIcon} iconColor="red"/>
		  </Grid>
		  <Grid item container direction="row" justify="space-between" spacing={3}>
		    <Grid item xs md={9}>
			  <InfoCard title="Last 24h activity" subTitle="">
			    <div style={{ height: 330 }}>
				  <Full24hChart data={convertDataTo24hChartData(this.state.data.analyzer.last24hData.records)} />
				</div>
			  </InfoCard>
			</Grid>
			<Grid item md container direction="column" spacing={3}>
			  <Grid item>
			    <InfoCard title={topRecord.record} subTitle={`Top record - ${topRecord.date.toLocaleDateString(undefined, {timeZone: 'UTC'})}`} Icon={EmojiEventsIcon} iconColor="yellow"/>
			  </Grid>
			  <Grid item style={{ flex: 1 }}>
			    <LatestRecords fid={this.state.data.id} style={{ minHeight: '100%' }} />
			  </Grid>
			</Grid>
		  </Grid>
		  <Grid item xs={12}>
		    <InfoCard title="Daily activity" subTitle="">
			  <div style={{ height: 330 }}>
			    <WeeklyChart data={convertDataToAbstractChartData(this.state.data.analyzer.weeklyData())} />
			  </div>
			 </InfoCard>
		  </Grid>
		  <Grid item xs={12}>
		    <InfoCard title="Hourly activity" subTitle="">
			  <div style={{ height: 330 }}>
			    <HourlyChart data={convertDataToAbstractChartData(this.state.data.analyzer.hourlyData())} />
			  </div>
			 </InfoCard>
		  </Grid>
		  <Grid item xs>
		    <Typography variant="body2" color="textSecondary" component="p" align="center">Tracking for this faction started on {this.state.data.analyzer.trackingStart.toLocaleDateString()} (local time)</Typography>
		  </Grid>
		</Grid>
	  </>
    );
  }
}

Details.contextType = LayoutStateContext;

export default withStyles(styles)(Details);