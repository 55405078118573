import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
//import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
//import Button from '@material-ui/core/Button';
//import CloseIcon from '@material-ui/icons/Close';

/*const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));*/

export default function TransitionAlerts(props) {
  //const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  
  const {severity, onClose, children, ...rest} = props;
  return (
      <Collapse in={open}>
        <Alert severity={props.severity || "info"} onClose={() => {if(props.onClose) props.onClose(); setOpen(false);}} {...rest}>{props.children}</Alert>
      </Collapse>
  );
}
